<template>
  <div class="home">
    <a href="https://www.edxtech.com"
      ><img alt="Vue logo" src="@/assets/logo.png" class="mx-auto w-32"
    /></a>
    <!-- <div
      :style="{
        'background-image': 'url(' + require('@/assets/logo.png') + ')',
      }"
      class="bg-fixed max-w-sm mx-auto h-48 max-h-full"
    ></div> -->
    <div>
      <a> <vue-qr :text="qrCodeString"> </vue-qr></a>
    </div>
    <div>
      <button
        class="inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-yellow-500 rounded shadow ripple hover:shadow-lg hover:bg-yellow-600 focus:outline-none"
        @click="getLocation"
      >
        Generate QR Code
      </button>
    </div>

    <div>
      <pre>
        QRSTR: {{ qrCodeString }}
      </pre>
    </div>

    <!-- ERROR STRINGS-->
    <div v-if="Array.isArray(errorStrings) && errorStrings.length">
      <div class="text-red-500">Errors:</div>
      <div v-for="(err, index) in errorStrings" :key="index">
        <span class="text-red-400"> {{ index + 1 }}: {{ err }}</span>
      </div>
    </div>

    <center>
      <!-- <div class="p-5 flex flex-wrap justify-center"> -->
      <!-- DIV wrapping cards -->
      <!-- items-center -->
      <!--Card 1-->
      <div
        class="max-w-sm rounded overflow-hidden shadow-lg border-solid border-2 border-gray-100"
      >
        <!-- <img class="w-full" src="/mountain.jpg" alt="Mountain" /> -->
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Location
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/watchPosition"
              ><span class="text-blue-500 bg-gray-200 rounded-full">
                ?
              </span></a
            >
          </div>
          <!-- start table -->
          <div class="w-full">
            <div
              class="shadow overflow-hidden rounded border-b border-gray-200"
            >
              <table class="min-w-full bg-white">
                <tbody class="text-gray-700">
                  <tr>
                    <td class="w-1/3 py-3 px-4 text-right">lat:</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ location.latitude | truncate(8) }}
                    </td>
                  </tr>
                  <tr class="bg-gray-100">
                    <td class="w-1/3 text-right py-3 px-4">lng:</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ location.longitude | truncate(8) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-1/3 text-right py-3 px-4">alt:</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ location.altitude | truncate(8) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end table -->
        </div>
      </div>
      <!-- end Card 1 -->

      <!--Card 2-->
      <div
        class="max-w-sm rounded overflow-hidden shadow-lg border-solid border-2 border-gray-100 mt-4"
      >
        <!-- <img class="w-full" src="/mountain.jpg" alt="Mountain" /> -->
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Orientation
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/API/Window/deviceorientation_event"
              ><span class="text-blue-500 bg-gray-200 rounded-full">
                ?
              </span></a
            >
          </div>
          <!-- start table -->
          <div class="w-full">
            <div
              class="shadow overflow-hidden rounded border-b border-gray-200"
            >
              <table class="min-w-full bg-white">
                <tbody class="text-gray-700">
                  <tr>
                    <td class="w-1/3 py-3 px-4 text-right">compass (Z)</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ orientation.compass | truncate }}&deg;
                    </td>
                  </tr>
                  <tr class="bg-gray-100">
                    <td class="w-1/3 text-right py-3 px-4">X-axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ orientation.x | truncate }}&deg;
                    </td>
                  </tr>
                  <tr>
                    <td class="w-1/3 text-right py-3 px-4">Y-axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ orientation.y | truncate }}&deg;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end table -->
        </div>
      </div>
      <!-- end Card 2 -->

      <!--Card 3-->
      <div
        class="max-w-sm rounded overflow-hidden shadow-lg border-solid border-2 border-gray-100 mt-4"
      >
        <!-- <img class="w-full" src="/mountain.jpg" alt="Mountain" /> -->
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Accelerometer (w/Gravity)
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/API/Window/devicemotion_event"
              ><span class="text-blue-500 bg-gray-200 rounded-full">
                ?
              </span></a
            >
          </div>
          <!-- start table -->
          <div class="w-full">
            <div
              class="shadow overflow-hidden rounded border-b border-gray-200"
            >
              <table class="min-w-full bg-white">
                <tbody class="text-gray-700">
                  <tr>
                    <td class="w-1/3 py-3 px-4 text-right">X-Axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.accelerometer_gx | truncate }} m/s<sup>2</sup>
                    </td>
                  </tr>
                  <tr class="bg-gray-100">
                    <td class="w-1/3 text-right py-3 px-4">Y-axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.accelerometer_gy | truncate }} m/s<sup>2</sup>
                    </td>
                  </tr>
                  <tr>
                    <td class="w-1/3 text-right py-3 px-4">Z-axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.accelerometer_gz | truncate }} m/s<sup>2</sup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end table -->
        </div>
      </div>
      <!-- end Card 3 -->

      <!--Card 4-->
      <div
        class="max-w-sm rounded overflow-hidden shadow-lg border-solid border-2 border-gray-100 mt-4"
      >
        <!-- <img class="w-full" src="/mountain.jpg" alt="Mountain" /> -->
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Accelerometer
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/API/Window/devicemotion_event"
              ><span class="text-blue-500 bg-gray-200 rounded-full">
                ?
              </span></a
            >
          </div>
          <!-- start table -->
          <div class="w-full">
            <div
              class="shadow overflow-hidden rounded border-b border-gray-200"
            >
              <table class="min-w-full bg-white">
                <tbody class="text-gray-700">
                  <tr>
                    <td class="w-1/3 py-3 px-4 text-right">X-Axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.accelerometer_x | truncate }} m/s<sup>2</sup>
                    </td>
                  </tr>
                  <tr class="bg-gray-100">
                    <td class="w-1/3 text-right py-3 px-4">Y-axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.accelerometer_y | truncate }} m/s<sup>2</sup>
                    </td>
                  </tr>
                  <tr>
                    <td class="w-1/3 text-right py-3 px-4">Z-axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.accelerometer_z | truncate }} m/s<sup>2</sup>
                    </td>
                  </tr>
                  <tr class="bg-gray-100">
                    <td class="w-1/3 text-right py-3 px-4">Data Interval</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.acceleromter_i }} ms
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end table -->
        </div>
      </div>
      <!-- end Card 4 -->

      <!--Card 5-->
      <div
        class="max-w-sm rounded overflow-hidden shadow-lg border-solid border-2 border-gray-100 mt-4"
      >
        <!-- <img class="w-full" src="/mountain.jpg" alt="Mountain" /> -->
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">Gyroscope</div>
          <!-- start table -->
          <div class="w-full">
            <div
              class="shadow overflow-hidden rounded border-b border-gray-200"
            >
              <table class="min-w-full bg-white">
                <tbody class="text-gray-700">
                  <tr>
                    <td class="w-1/3 py-3 px-4 text-right">X-Axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.gyroscope_x | truncate }} &deg;/s
                    </td>
                  </tr>
                  <tr class="bg-gray-100">
                    <td class="w-1/3 text-right py-3 px-4">Y-axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.gyroscope_y | truncate }} &deg;/s
                    </td>
                  </tr>
                  <tr>
                    <td class="w-1/3 text-right py-3 px-4">Z-axis</td>
                    <td class="w-1/3 text-left py-3 px-4">
                      {{ motion.gyroscope_z | truncate }} &deg;/s
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end table -->
        </div>
      </div>
      <!-- end Card 5 -->

      <!-- </div> -->
      <!-- end DIV wrapping cards -->
    </center>

    <div class="mt-16"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import VueQr from "vue-qr";

export default {
  name: "Home",
  data() {
    return {
      location: { latitude: 0.0, longitude: 0.0, altitude: 0.0 },
      locationID: 0,
      orientation: { compass: 0.0, x: 0.0, y: 0.0 },
      motion: {
        accelerometer_gx: 0.0,
        accelerometer_gy: 0.0,
        accelerometer_gz: 0.0,
        accelerometer_x: 0.0,
        accelerometer_y: 0.0,
        accelerometer_z: 0.0,
        acceleromter_i: 0.0,
        gyroscope_z: 0.0,
        gyroscope_x: 0.0,
        gyroscope_y: 0.0,
      },
      qrCodeString: "",
      errorStrings: [],
    };
  },
  methods: {
    getLocation() {
      this.updateQrData();
    },
    updateLocation(location) {
      this.location.latitude = location.coords.latitude;
      this.location.longitude = location.coords.longitude;
      this.location.altitude = location.coords.altitude;
    },
    updateQrData() {
      let data = {
        location: this.location,
        orientation: this.orientation,
        timestamp: Date.now(),
      };
      this.qrCodeString = JSON.stringify(data);
    },
    storeOrientationData(event) {
      this.orientation.compass = event.alpha;
      this.orientation.x = event.beta;
      this.orientation.y = event.gamma;
    },
    storeMotionData(event) {
      this.motion.accelerometer_gx = event.accelerationIncludingGravity.x;
      this.motion.accelerometer_gy = event.accelerationIncludingGravity.y;
      this.motion.accelerometer_gz = event.accelerationIncludingGravity.z;

      this.motion.accelerometer_x = event.acceleration.x;
      this.motion.accelerometer_y = event.acceleration.y;
      this.motion.accelerometer_z = event.acceleration.z;

      this.motion.acceleromter_i = event.interval;

      this.motion.gyroscope_z = event.rotationRate.alpha;
      this.motion.gyroscope_x = event.rotationRate.beta;
      this.motion.gyroscope_y = event.rotationRate.gamma;
    },
  },
  filters: {
    truncate(num, numDigits) {
      let d = numDigits ? numDigits : 6;
      if (!num) return "";
      num = parseFloat(num);
      return num.toFixed(d).toString();
    },
  },
  mounted() {
    // Request permission for iOS 13+ devices
    if (
      window.DeviceMotionEvent &&
      typeof window.DeviceMotionEvent.requestPermission === "function"
    ) {
      window.DeviceMotionEvent.requestPermission();
    }
    window.addEventListener(
      "deviceorientationabsolute",
      this.storeOrientationData
    );
    window.addEventListener("devicemotion", this.storeMotionData);

    let options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    if (navigator.geolocation) {
      // https://w3c.github.io/geolocation-api/#position_options_interface
      this.locationID = navigator.geolocation.watchPosition(
        this.updateLocation,
        (err) => {
          let msg = "GPS error: ";
          switch (err.code) {
            case 1:
              msg += "PERMISSION DENIED";
              break;
            case 2:
              msg += "POSITION_UNAVAILABLE";
              break;
            case 3:
              msg += "TIMEOUT";
              break;
          }
          msg += " :: " + err.message;
          this.errorStrings.push(msg);
        },
        options
      );
    } else {
      this.errorStrings.push("Geolocation is not supported by this browser");
    }
  },
  unmounted() {
    window.removeEventListener(
      "deviceorientationabsolute",
      this.storeOrientationData
    );
    window.removeEventListener("devicemotion", this.storeMotionData);
    navigator.geolocation.clearWatch(this.locationID);
  },
  components: {
    VueQr,
  },
};
</script>
